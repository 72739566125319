<template>
  <div class="faq_block">
    <b-modal id="feedback" hide-footer size="lg">
      <div class="feedback-wrapper-modal">
        <div class="feedback-element">
          <h3>Оставьте свое сообщение для обратной связи</h3>
        </div>
        <div class="feedback-element">
          <span>Фио</span>
          <input v-model="data.name" />
        </div>
        <div class="feedback-element">
          <span>Номер телефона</span>
          <input v-model="data.phone" v-mask="'+7 (###) ### ##-##'" />
        </div>
        <div class="feedback-element">
          <span>Email</span>
          <input v-model="data.email" />
        </div>
        <div class="feedback-element">
          <span>Сообщение</span>
          <textarea v-model="data.message" style="    border-radius: 10px;
    padding: 8px;"></textarea>
        </div>
        <div class="feedback-element">
          <vue-recaptcha @verify="toggleCaptcha" sitekey="6Le4a80jAAAAAIsLHciqcozvWuzy4bzyXl3FAX8R"></vue-recaptcha>
        </div>

        <div class="feedback-send">
          <el-button @click="send">Отправить</el-button>
        </div>
      </div>
    </b-modal>
    <div class="faq_main_block">
      <div class="faq_main_container">
        <div class="faq_main_title">FAQ</div>

        <div class="faq__categories">
          <router-link
            v-for="category in categories"
            :to="{ name: 'faq-item', params: { id: category.id } }"
            class="faq__categories__item"
          >
            <img :src="category.icon ? getIconUrl(category.icon) : ''" alt="Иконка" class="faq__categories__item__icon">
            {{ category.translations.find(({locale}) => locale === currentLocale).name }}
          </router-link>
        </div>

        <!--        <div class="faq_main_menu">-->
        <!--          <div class="faq_main_menu_group">-->
        <!--            <div class="faq_main_menu_group_item">-->
        <!--              <div class="faq_main_menu_group_item_img"/>-->
        <!--              <div class="faq_main_menu_group_text">Пароль</div>-->
        <!--            </div>-->
        <!--            <div class="faq_main_menu_group_item_payment">-->
        <!--              <div class="faq_main_menu_group_item_img"/>-->
        <!--              <div class="faq_main_menu_group_text">Оплата</div>-->
        <!--            </div>-->
        <!--            <router-link to="/faq/account" class="faq_main_menu_group_item_account">-->
        <!--              <div class="faq_main_menu_group_item_img"/>-->
        <!--              <div class="faq_main_menu_group_text">Аккаунт</div>-->
        <!--            </router-link>-->
        <!--          </div>-->
        <!--          <div class="faq_main_menu_group">-->
        <!--            <router-link to="/faq/account" class="faq_main_menu_group_item_account">-->
        <!--              <div class="faq_main_menu_group_item_img"/>-->
        <!--              <div class="faq_main_menu_group_text">Аккаунт</div>-->
        <!--            </router-link>-->
        <!--            <div class="faq_main_menu_group_item_course">-->
        <!--              <div class="faq_main_menu_group_item_img"/>-->
        <!--              <div class="faq_main_menu_group_text">Запись на курс</div>-->
        <!--            </div>-->
        <!--            <div class="faq_main_menu_group_item">-->
        <!--              <div class="faq_main_menu_group_item_img"/>-->
        <!--              <div class="faq_main_menu_group_text">Пароль</div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->

      </div>
    </div>

    <div class="faq_support_block">
      <div class="faq_support_container">
        <div class="faq_support_form">
          <div class="faq_support_form_question">
            <div class="faq_support_form_question_img"/>
          </div>
          <div class="faq_support_form_text_block">
            <div class="faq_support_form_text_question">
              {{ $t("Can't find the answer to your question?") }}
            </div>
            <div class="faq_support_form_text_answer">
              {{ $t("Write a support request") }}
            </div>
          </div>
          <a @click="$bvModal.show('feedback')" class="faq_support_form_write">
            <span class="faq_support_form_write_label">{{ $t("Write") }}</span>
            <span class="faq_support_form_write_label_mobile">{{ $t("Write a support request") }}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {VueRecaptcha} from 'vue-recaptcha';
import VueMask from 'v-mask'
import Vue from "vue";

export default {
  components: {VueRecaptcha},
  name: "FAQ",
  beforeCreate() {
    Vue.use(VueMask);
  },
  data() {
    return {
      data: {
        captureButton: false,
        name: '',
        phone: '',
        message: '',
        email: ''
      },
      categories: [],
      currentLocale: this.$i18n.locale,
    };
  },
  methods: {
    toggleCaptcha() {
      this.captureButton = !this.captureButton;
    },
    send() {
      if (!this.captureButton) {
        Vue.toastr({
          description: 'Подтвердите что вы не робот',
          type: 'error'
        })
        return
      }

      function validateEmail(email) {
        var pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(String(email).toLowerCase());
      }

      if (!validateEmail(this.data.email)) {
        Vue.toastr({
          description: 'Неверно указан имейл',
          type: 'error'
        })
        return
      }

      this.$http.post(`${API_ROOT}/api/feedback`, this.data)
        .then(() => {
          this.$bvModal.hide('feedback')
          this.data = {
            name: '',
            phone: '',
            message: '',
            email: ''
          };
          this.captureButton = false;

          Vue.toastr({
            description: 'Сообщение успешно отправлено',
            type: 'success'
          });
        })
        .catch(err => {
          console.error(err);
          this.captureButton = false;
        })
    },
    getIconUrl(path) {
      return API_ROOT + path;
    }
  },
  mounted() {
    this.$http.get(`${API_ROOT}/api/fag/categories`)
      .then(({data}) => {
        this.categories = data;
      })
      .catch(err => {
        console.error(err);
      })
  }
}
</script>

<style>
.modal-header .close {
  font-size: 35px;
}
</style>
